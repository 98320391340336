import React from 'react';
import {IGalleryStyleParams, IPropsInjectedByViewerScript, IGallerySantaProps} from '../../types/types';
import {translate} from '@wix/wixstores-client-core/dist/src/viewer-script/utils';

const PropsContext = React.createContext<IProvidedGlobalProps>(undefined);

export interface IProvidedGlobalProps {
  globals?: Pick<
    IPropsInjectedByViewerScript & IGallerySantaProps,
    | 'appLoadBI'
    | 'dimensions'
    | 'experiments'
    | 'filterModels'
    | 'filterProducts'
    | 'filterProductsOnMobile'
    | 'focusedProductIndex'
    | 'getCategoryProducts'
    | 'handleProductItemClick'
    | 'hasMoreProductsToLoad'
    | 'hasSelectedFilters'
    | 'isFirstPage'
    | 'isInteractive'
    | 'isLiveSiteMode'
    | 'isMobile'
    | 'isRTL'
    | 'loadMoreProducts'
    | 'loading'
    | 'mainCollectionId'
    | 'openQuickView'
    | 'productIdToProductPageUrlMap'
    | 'products'
    | 'setProductsPerPage'
    | 'sortProducts'
    | 'textsMap'
    | 'toggleFiltersModalVisibility'
    | 'totalProducts'
    | 'updateLayout'
  > & {styleParams: IGalleryStyleParams};
}

export function withGlobalPropsProvider(Component) {
  return (props: IPropsInjectedByViewerScript & IGallerySantaProps) => {
    const {
      appLoadBI,
      dimensions,
      experiments,
      filterModels,
      filterProducts,
      filterProductsOnMobile,
      focusedProductIndex,
      getCategoryProducts,
      handleProductItemClick,
      hasMoreProductsToLoad,
      hasSelectedFilters,
      isFirstPage,
      isInteractive,
      isLiveSiteMode,
      isMobile,
      isRTL,
      loadMoreProducts,
      loading,
      mainCollectionId,
      openQuickView,
      productIdToProductPageUrlMap,
      products,
      setProductsPerPage,
      sortProducts,
      style,
      textsMap,
      toggleFiltersModalVisibility,
      totalProducts,
      updateLayout,
      ...passThroughProps
    } = props;

    return (
      <PropsContext.Provider
        value={{
          globals: {
            appLoadBI,
            dimensions,
            experiments,
            filterModels,
            filterProducts,
            filterProductsOnMobile,
            focusedProductIndex,
            getCategoryProducts,
            handleProductItemClick,
            hasMoreProductsToLoad,
            hasSelectedFilters,
            isFirstPage,
            isInteractive,
            isLiveSiteMode,
            isMobile,
            isRTL,
            loadMoreProducts,
            loading,
            mainCollectionId,
            openQuickView,
            productIdToProductPageUrlMap,
            products,
            setProductsPerPage,
            sortProducts,
            styleParams: style.styleParams,
            textsMap,
            toggleFiltersModalVisibility,
            totalProducts,
            updateLayout,
          },
        }}>
        <Component {...passThroughProps} />
      </PropsContext.Provider>
    );
  };
}

export function withGlobalProps(Component: any): React.FunctionComponent | any {
  return props => (
    <PropsContext.Consumer>
      {globalProps => <Component {...globalProps} {...props} ref={props.innerRef} />}
    </PropsContext.Consumer>
  );
}

function getTranslation(translations: {[key: string]: string}): TranslationFunction {
  return (translationKey: string, values: {}) => {
    return translate(translations[translationKey], values);
  };
}

export function withTranslations(Component: any): React.FunctionComponent | any {
  return props => {
    return (
      <PropsContext.Consumer>
        {globalProps => <Component {...props} t={getTranslation(globalProps.globals.textsMap as any)} />}
      </PropsContext.Consumer>
    );
  };
}

export interface IProvidedTranslationProps {
  t?: TranslationFunction;
}

type TranslationFunction = (string, values?: {}, fallback?: string) => string;
